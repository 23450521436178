


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class OrderBumpSearchBox extends Vue {
  orderBumpSearchText: String = '';

  @Prop({ default: '' }) label: String;
  @Prop({ default: 'Digite sua busca' }) placeholder: String;
  @Prop({ required: true }) itemsList: Array<any>;

  get filteredItems() {
    if (!this.orderBumpSearchText) return this.itemsList;

    return this.itemsList.filter(item => item.title.toLowerCase().includes(this.orderBumpSearchText.toLowerCase()));
  }

  @Watch('filteredItems', { immediate: true })
  onChangeFilteredItems() {
    this.$emit('filter', this.filteredItems);
  }
}
